import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { Row, Col, Button } from 'antd';

export default function Error404() {
  return (
    <Row className="da-bg-color-primary-4 da-bg-color-dark-90 da-text-center">
      <Col className="da-error-content da-py-32" span={24}>
        <Row className="da-h-100" align="middle" justify="center">
          <Col>
            <div className="da-position-relative da-mt-sm-0 da-mt-64 da-mb-32">
              <div className="da-error-content-circle da-bg-dark-100"></div>
              <Image
                className="da-position-relative da-d-block da-m-auto"
                src={'/vendor-theme/images/pages/error/404.svg'}
                width={200}
                height={200}
                alt="404"
              />
            </div>

            <h1 className="da-error-content-title da-mb-sm-0 da-mb-8 da-font-weight-300">Ops, 404...</h1>

            <p className="da-mb-32 da-p1-body">Esse conteúdo não foi encontrado!</p>

            <Link href="/">
              <a>
                <Button type="primary">Voltar para página inicial</Button>
              </a>
            </Link>
          </Col>
        </Row>
      </Col>

      {/* <Col span={24} className="da-py-24">
        <p className="da-mb-0 da-badge-text">COPYRIGHT ©2020 Hypeople, All rights Reserved</p>
      </Col> */}
    </Row>
  );
}
